package tta.destinigo.talktoastro.feature_course.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ValidateCouponRequest(
    @SerialName("course_id") val courseId:String?,
    @SerialName("price") val price:String?,
    @SerialName("coupon_code") val couponCode:String?,
)
