package tta.destinigo.talktoastro.feature_course.data.response.course

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_course.data.response.courses.CourseData
import tta.destinigo.talktoastro.shared.data.CommonResponse

@Serializable
data class CourseResponse(
    @SerialName("data") val data: CourseData? = null
) : CommonResponse()
