package tta.destinigo.talktoastro.feature_course.persentation.course_host

import com.arkivanov.decompose.ComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.StackNavigation
import com.arkivanov.decompose.router.stack.childStack
import com.arkivanov.decompose.router.stack.pushNew
import com.arkivanov.decompose.router.stack.webhistory.WebHistoryController
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_course.persentation.course_page.CourseComponent
import tta.destinigo.talktoastro.feature_course.persentation.courses_page.CoursesComponent

@OptIn(ExperimentalDecomposeApi::class)
class HostComponent(
    componentContext: ComponentContext,
    deepLink: String? = null,
    webHistoryController: WebHistoryController? = null,
    private val onClose: () -> Unit
) : HostRoot, ComponentContext by componentContext {

    private val navigation = StackNavigation<CourseFeedConfig>()

    override val childStack = childStack(
        source = navigation,
        serializer = CourseFeedConfig.serializer(),
        initialStack = {
            getInitialStack(
                webHistoryPaths = webHistoryController?.historyPaths,
                deepLink = deepLink
            )
        },
        childFactory = ::createChild,
        handleBackButton = true
    )

    init {
        webHistoryController?.attach(
            navigator = navigation,
            serializer = CourseFeedConfig.serializer(),
            stack = childStack,
            getPath = Companion::getPathForConfig,
            getConfiguration = Companion::getConfigForPath,
            onWebNavigation = { _, _ -> true }
        )
    }

    private fun createChild(
        config: CourseFeedConfig,
        componentContext: ComponentContext
    ): HostRoot.Child = when (config) {
        is CourseFeedConfig.CoursesPageConfig -> onCoursesPage(componentContext)
        is CourseFeedConfig.CoursePageConfig -> onCoursePage(componentContext,config.slug)
    }

    private fun onCoursesPage(componentContext: ComponentContext) =
        HostRoot.Child.CoursesPageChild(
            CoursesComponent(
                context = componentContext,
                onBackButtonClick = { onClose() },
                onRegisterButtonClick = { slug ->
                    navigation.pushNew(
                            CourseFeedConfig.CoursePageConfig(slug)
                        )
                }
            )
        )

    private fun onCoursePage(componentContext: ComponentContext, slug: String) =
        HostRoot.Child.CoursePageChild(
            CourseComponent(
                context = componentContext,
                slug = slug,
                onClose = { onClose() }
            )
        )

    @Serializable
    sealed class CourseFeedConfig {
        @Serializable
        data object CoursesPageConfig : CourseFeedConfig()

        @Serializable
        data class CoursePageConfig(val slug: String) : CourseFeedConfig()
    }

    private companion object {
        private const val COURSE = "course"

        private fun getInitialStack(
            webHistoryPaths: List<String>?,
            deepLink: String?
        ): List<CourseFeedConfig> {
            val historyConfigs = webHistoryPaths
                ?.filterNot(String::isEmpty)
                ?.map(::getConfigForPath)
                ?.distinct()
                ?: emptyList()

            return historyConfigs.ifEmpty { getInitialStack(deepLink) }
        }

        private fun getInitialStack(deepLink: String?): List<CourseFeedConfig> = if (deepLink == null) {
            listOf(CourseFeedConfig.CoursesPageConfig)}
        else{
            listOfNotNull(
                CourseFeedConfig.CoursesPageConfig,
                getConfigForPath(deepLink)
            ).distinct()
        }

        private fun getPathForConfig(config: CourseFeedConfig): String = when (config) {
            is CourseFeedConfig.CoursesPageConfig -> "/$COURSE"
            is CourseFeedConfig.CoursePageConfig -> "/$COURSE/${config.slug}"
        }

        private fun getConfigForPath(path: String): CourseFeedConfig {
            val segments = path.removePrefix("/").split("/")
            return if (segments.firstOrNull() == COURSE) {
                val slug = segments.getOrNull(1)
                if (slug.isNullOrBlank()) CourseFeedConfig.CoursesPageConfig else CourseFeedConfig.CoursePageConfig(slug)
            } else CourseFeedConfig.CoursesPageConfig
        }
    }
}




