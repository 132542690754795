package tta.destinigo.talktoastro.feature_course.persentation.course_host

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.extensions.compose.stack.Children
import com.arkivanov.decompose.extensions.compose.stack.animation.fade
import com.arkivanov.decompose.extensions.compose.stack.animation.plus
import com.arkivanov.decompose.extensions.compose.stack.animation.predictiveback.predictiveBackAnimation
import com.arkivanov.decompose.extensions.compose.stack.animation.scale
import com.arkivanov.decompose.extensions.compose.stack.animation.stackAnimation
import com.arkivanov.decompose.extensions.compose.subscribeAsState
import tta.destinigo.talktoastro.feature_course.persentation.course_page.CoursePage
import tta.destinigo.talktoastro.feature_course.persentation.courses_page.CoursesPage

@OptIn(ExperimentalDecomposeApi::class)
@Composable
fun CourseHostPage(component: HostComponent) {

    val childStack by component.childStack.subscribeAsState()

    Children(
        stack = childStack,
        animation = predictiveBackAnimation(
            backHandler = component.backHandler,
            fallbackAnimation = stackAnimation(fade() + scale()),
            onBack = { Logger.d("onBack pressed in PostHostPage") }
        )
    ) { child ->
        when (val instance = child.instance) {
            is HostRoot.Child.CoursePageChild -> CoursePage(instance.component)
            is HostRoot.Child.CoursesPageChild -> CoursesPage(instance.component)
        }
    }
}
