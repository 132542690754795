package tta.destinigo.talktoastro.feature_course.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


/*'course_id' => 'bail|required|exists:courses,id',
                'course_purchage_id' => 'bail|required|exists:course_purchases,id',
                'txn_id' => 'bail|required|exists:transaction_history,id',*/
@Serializable
data class CourseRazorpayOrderRequest(
    @SerialName("course_id") val courseId: String,
    @SerialName("course_purchage_id") val coursePurchageId: String,
    @SerialName("txn_id") val txnId: String,
    @SerialName("domain") val domainName: String
)
