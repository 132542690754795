package tta.destinigo.talktoastro.feature_course.data.response.course_order_id

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.shared.data.CommonResponse

@Serializable
data class CourseOrderIdResponse(
    @SerialName("data") val data: CourseOrderIdData?
) : CommonResponse()

@Serializable
data class CourseOrderIdData(
    @SerialName("razorpay_order_id") var orderId: String? = null,
)

