package tta.destinigo.talktoastro.feature_course.persentation.course_page.widget

import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CornerSize
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material.icons.filled.WbSunny
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.koin.dsl.module
import tta.destinigo.talktoastro.shared.theme.primaryDarkBlur

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CourseTopBar(
    onClickHome: () -> Unit,
) {
    // Define window size class
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val isCompact = windowSizeClass == WindowWidthSizeClass.Compact


    Card(
        elevation = CardDefaults.cardElevation(4.dp),
        modifier = Modifier.fillMaxWidth(),
        colors = CardDefaults.cardColors(Color.White),
        shape = MaterialTheme.shapes.small.copy(CornerSize(0.dp))
    ) {

        val padding = if (isCompact) 8.dp else 24.dp

        TopAppBar(
            modifier = Modifier.fillMaxWidth().padding(horizontal = padding),
            actions = {

                // Blogs Tab

                if (isCompact.not()) {
                    Text(
                        text = "Home",
                        fontSize = 16.sp,
                        modifier = Modifier.padding(horizontal = 16.dp).clickable {
                            onClickHome.invoke()
                        },
                        style = TextStyle(fontWeight = FontWeight.Bold)
                    )
                }

                // FREE Button
//                WhatsAppSupportTxt()
            },
            colors = TopAppBarDefaults.topAppBarColors(Color.White),
            title = {
                Row(verticalAlignment = Alignment.CenterVertically) {

                    Icon(
                        imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                        contentDescription = "Back",
                        modifier = Modifier.size(24.dp).clickable { onClickHome.invoke() },

                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    // Icon
                    Icon(
                        imageVector = Icons.Default.WbSunny, // Add your logo resource
                        contentDescription = "Logo",
                        modifier = Modifier.size(24.dp),
                        tint = primaryDarkBlur
                    )

                    Spacer(modifier = Modifier.width(8.dp))

                    // Title
                    Text(
                        text = "Astrology Learning Program",
                        style = TextStyle(fontWeight = FontWeight.Bold),
                        color = primaryDarkBlur,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis
                    )
                }
            }
        )
    }


}



