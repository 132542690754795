package tta.destinigo.talktoastro.feature_course.data.response.status_verify

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.shared.data.CommonResponse

@Serializable
data class CourseBuyStatusResponse(
    @SerialName("data") val data: String
) : CommonResponse()
