package tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.dailog

import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.shared.persentation.CommonButton
import tta.destinigo.talktoastro.shared.theme.GreenDark20
import tta.destinigo.talktoastro.shared.theme.primaryDarkerColor

@Composable
fun SuccessBuyDialog(
    onDismiss: () -> Unit
) {

    AlertDialog(
        onDismissRequest = {
            onDismiss.invoke()
        },
        title = {
            Text(
                text = "Enrollment Successful",
                style = MaterialTheme.typography.titleMedium,
                fontWeight = FontWeight.Bold
            )
        },
        text = {
            Text(
                text = "Thank you for purchasing this course! We truly appreciate your interest in learning with us. A meeting link will be shared with you shortly via your WhatsApp number. Please ensure your contact details are correct to avoid any delays. We look forward to connecting with you and helping you embark on your learning journey!",
                style = MaterialTheme.typography.bodyMedium,
                textAlign = TextAlign.Center
            )
        },
        confirmButton = {
            CommonButton(
                onClick = {
                    onDismiss.invoke()
                },
                buttonText = "Ok",
                modifier = Modifier,
                buttonColor = primaryDarkerColor
            )
        },
        icon = {
            Icon(
                imageVector = Icons.Default.CheckCircle,
                contentDescription = "Success Icon",
                tint = GreenDark20,
                modifier = Modifier.size(48.dp)
            )
        }
    )

}
