package tta.destinigo.talktoastro.feature_course.persentation.course_page

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.LazyListState
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Tab
import androidx.compose.material3.TabRow
import androidx.compose.material3.TabRowDefaults.SecondaryIndicator
import androidx.compose.material3.TabRowDefaults.tabIndicatorOffset
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.input.nestedscroll.NestedScrollConnection
import androidx.compose.ui.input.nestedscroll.NestedScrollSource
import androidx.compose.ui.input.nestedscroll.nestedScroll
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import kotlinx.coroutines.launch
import tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.CourseHeaderSection
import tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.registration_form.RegistrationForm
import tta.destinigo.talktoastro.feature_courses.persentation.course_page.widget.sections.CurriculumSection
import tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.sections.FaqSection
import tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.sections.InstructorsSection
import tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.sections.OverViewSection
import tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.CourseTopBar
import tta.destinigo.talktoastro.shared.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.shared.remote.Resources
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.primaryDarkerColor

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CoursePage(component: CourseComponent) {

    val state by component.state.collectAsState()
    val courses by component.courses.collectAsState()

    val tabs = remember { listOf("Overview", "Curriculum", "Instructor", "Faq") }
//    val icons = remember {
//        listOf(
//            Icons.Filled.ViewAgenda,
//            Icons.Filled.ContentPasteGo,
//            Icons.Filled.Info,
//            Icons.Filled.QuestionAnswer
//        )
//    }

    var selectedTabIndex by remember { mutableStateOf(0) }
    val lazyListState = rememberLazyListState()
    val lazyListSecond = rememberLazyListState()

    val coroutineScope = rememberCoroutineScope()
    val windowWidth = calculateWindowSizeClass().widthSizeClass

    val isMobileView = windowWidth == WindowWidthSizeClass.Compact
    val isTabletMode = windowWidth == WindowWidthSizeClass.Medium

    if ((state as? Resources.Loading)?.isLoading == true) {
        LoadingProgressDialog {}
    }

    val isSticky by remember {
        derivedStateOf {
            val firstVisibleItem = lazyListState.layoutInfo.visibleItemsInfo.firstOrNull()
            firstVisibleItem?.index == 2 && firstVisibleItem.offset <= 0 // Check for the third item
        }
    }

    Scaffold(
        containerColor = background_color,
        topBar = { CourseTopBar { component.onBackPress() } }
    ) { paddingValues ->
        courses?.let { course ->
            Box(modifier = Modifier.fillMaxSize().padding(paddingValues)) {
                if (!isMobileView) {
                    DesktopView(
                        course = course,
                        tabs = tabs,
                        selectedTabIndex = selectedTabIndex,
                        onTabSelected = { index ->
                            selectedTabIndex = index
                            coroutineScope.launch { lazyListState.animateScrollToItem(index + 1) }
                        },
                        lazyListState = lazyListState,
                        lazyListSecond = lazyListSecond,
                        isTabletMode = isTabletMode,
                        component = component,
                        isSticky
                    )
                } else {
                    MobileView(
                        course = course,
                        lazyListState = lazyListState,
                        component = component,
                    )
                }
            }
        }
    }
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalFoundationApi::class)
@Composable
private fun DesktopView(
    course: CourseInfo,
    tabs: List<String>,
    selectedTabIndex: Int,
    onTabSelected: (Int) -> Unit,
    lazyListState: LazyListState,
    lazyListSecond: LazyListState,
    isTabletMode: Boolean,
    component: CourseComponent,
    isSticky: Boolean
) {
    val horizontalPadding = if (isTabletMode) 18.dp else 36.dp

    // Nested scroll connection to share scroll events between two LazyColumns
    val nestedScrollConnection = remember {
        object : NestedScrollConnection {
            override fun onPreScroll(available: Offset, source: NestedScrollSource): Offset {
                val delta = available.y
                val firstListReachedEnd =
                    lazyListSecond.layoutInfo.visibleItemsInfo.lastOrNull()?.index ==
                            lazyListSecond.layoutInfo.totalItemsCount - 1
                val firstConsumed = if (!firstListReachedEnd) {
                    lazyListSecond.dispatchRawDelta(-delta)
                } else {
                    0f
                }

                val secondListReachedStart = lazyListState.firstVisibleItemIndex == 0 &&
                        lazyListState.firstVisibleItemScrollOffset == 0
                val secondConsumed = if (firstListReachedEnd && !secondListReachedStart) {
                    lazyListState.dispatchRawDelta(-delta)
                } else {
                    0f
                }

                return Offset(0f, firstConsumed + secondConsumed)
            }
        }
    }

    Row(
        modifier = Modifier
            .fillMaxSize()
            .padding(horizontal = horizontalPadding)
            .nestedScroll(nestedScrollConnection)
    ) {
        LazyColumn(
            state = lazyListState,
            modifier = Modifier.weight(if (isTabletMode) 1f else 2f)
        ) {
            item { Spacer(modifier = Modifier.height(24.dp)) }
            item { CourseHeaderSection(course, calculateWindowSizeClass().widthSizeClass) }
            stickyHeader {
                TabRowContent(
                    tabs,
                    selectedTabIndex,
                    onTabSelected,
                    isSticky = isSticky
                )
            }
            items(tabs.size) { index ->
                if (index == selectedTabIndex) {
                    TabContent(index, course)
                }
            }
            item { Spacer(modifier = Modifier.height(32.dp)) }
        }

        LazyColumn(
            state = lazyListSecond,
            modifier = Modifier.padding(horizontal = 8.dp).weight(1f)
        ) {
            item { Spacer(modifier = Modifier.height(24.dp)) }
            item {
                RegistrationForm(
                    course = course,
                    modifier = Modifier
                        .wrapContentHeight(),
                    onClickSubmit = { name, email, mobile, countryCode ->
                        component.onSubmitForm(
                            name,
                            mobile,
                            email,
                            course.id.toString(),
                            countryCode
                        )
                    },
                    component = component,
                    onClickEnroll = { name, email, mobile, countryCode, couponCode ->
                        component.onBuyCourse(
                            name,
                            mobile,
                            email,
                            course.id.toString(),
                            countryCode = countryCode,
                            couponCode = couponCode
                        )
                    }
                )
            }
            item { Spacer(modifier = Modifier.height(24.dp)) }
        }

    }
}

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
private fun MobileView(
    course: CourseInfo,
    lazyListState: LazyListState,
    component: CourseComponent,
) {
    val coroutineScope = rememberCoroutineScope()

    LazyColumn(
        state = lazyListState,
        modifier = Modifier.padding(horizontal = 16.dp)
    ) {

        // item = 1
        item { Spacer(modifier = Modifier.height(16.dp)) }

        // item = 2
        item {
            CourseHeaderSection(course, calculateWindowSizeClass().widthSizeClass) {
                coroutineScope.launch { lazyListState.animateScrollToItem(lazyListState.layoutInfo.totalItemsCount - 8) }
            }
        }

        // item = 3
        item {
            Headline(title = "Overview")
        }

        // item = 4
        item {
            OverViewSection(
                modifier = Modifier,
                description = course.description ?: "",
                list = course.overviewSection,
                certificateUrl = course.uploadCertificates?.firstOrNull()
            )
        }

        // item = 5
        item {
            RegistrationForm(
                course = course,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 16.dp)
                    .wrapContentHeight(),
                onClickSubmit = { name, email, mobile, countryCode ->
                    component.onSubmitForm(name, mobile, email, course.id.toString(), countryCode)
                },
                component = component,
                onClickEnroll = { name, email, mobile, countryCode, couponCode ->
                    component.onBuyCourse(
                        name,
                        mobile,
                        email,
                        course.id.toString(),
                        countryCode = countryCode,
                        couponCode = couponCode
                    )
                }
            )
        }

        // item = 6
        item {
            Headline(title = "Curriculum")
        }

        // item = 7
        item {
            CurriculumSection(course.curriculamSection)
        }

        // item = 8
        item {
            Headline(title = "Instructors")
        }

        // item = 9
        item {
            InstructorsSection(modifier = Modifier, instructors = course.instructors!!)
        }

        // item = 10
        item {
            Headline(title = "Faq")
        }

        // item = 11
        item {
            FaqSection(modifier = Modifier, course.faqSection)
        }

        // item = 12
        item { Spacer(modifier = Modifier.height(42.dp)) }
    }
}

@Composable
private fun Headline(
    title: String
) {
    Column {
        Spacer(modifier = Modifier.height(8.dp))
        Text(
            maxLines = 1,
            text = title,
            style = MaterialTheme.typography.bodyLarge.copy(
                color = Color.Black,
                fontWeight = FontWeight.SemiBold
            )
        )
        Spacer(modifier = Modifier.height(6.dp))
        HorizontalDivider(
            color = primaryDarkerColor,
        )
    }


}

@Composable
private fun TabRowContent(
    tabs: List<String>,
    selectedTabIndex: Int,
    onTabSelected: (Int) -> Unit,
    icons: List<ImageVector>? = null,
    isSticky: Boolean,
    isMobile: Boolean = false
) {

    TabRow(
        selectedTabIndex = selectedTabIndex,
        containerColor = if (isSticky) Color.White else Color.Transparent,
        indicator = { tabPositions ->
            SecondaryIndicator(
                Modifier.tabIndicatorOffset(tabPositions[selectedTabIndex]),
                color = primaryDarkerColor
            )
        }
    ) {
        tabs.forEachIndexed { index, title ->
            Tab(
                selected = selectedTabIndex == index,
                onClick = { onTabSelected(index) },
                text = {
                    Text(
                        maxLines = 1,
                        text = title,
                        style = if (isMobile) MaterialTheme.typography.labelSmall.copy(
                            color = Color.Black,
                            fontWeight = if (selectedTabIndex == index) FontWeight.SemiBold else FontWeight.Normal
                        ) else MaterialTheme.typography.bodyLarge.copy(
                            color = Color.Black,
                            fontWeight = if (selectedTabIndex == index) FontWeight.SemiBold else FontWeight.Normal
                        )
                    )
                },
                icon = icons?.getOrNull(index)?.let {
                    {
                        Icon(
                            imageVector = it,
                            contentDescription = null,
                            modifier = Modifier.size(Scale.GLOBAL_ICON_SIZE_SMALL)
                        )
                    }
                }
            )
        }
    }
}

@Composable
private fun TabContent(index: Int, course: CourseInfo) {
    when (index) {
        0 -> OverViewSection(
            modifier = Modifier,
            description = course.description ?: "",
            list = course.overviewSection,
            certificateUrl = course.uploadCertificates?.firstOrNull()
        )

        1 -> CurriculumSection(course.curriculamSection)
        2 -> InstructorsSection(modifier = Modifier, instructors = course.instructors!!)
        3 -> FaqSection(modifier = Modifier, course.faqSection)
    }
}
