package tta.destinigo.talktoastro.feature_course.persentation.course_host

import com.arkivanov.decompose.router.stack.ChildStack
import com.arkivanov.decompose.value.Value
import tta.destinigo.talktoastro.feature_course.persentation.course_page.CourseComponent
import tta.destinigo.talktoastro.feature_course.persentation.courses_page.CoursesComponent

interface HostRoot {
    val childStack: Value<ChildStack<*, Child>>

    sealed class Child {
        data class CoursesPageChild(val component: CoursesComponent) : Child()
        data class CoursePageChild(val component: CourseComponent) : Child()
    }
}
