package tta.destinigo.talktoastro.feature_course.persentation.courses_page


import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.itemsIndexed
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.CourseTopBar
import tta.destinigo.talktoastro.shared.persentation.LoadingProgressDialog
import tta.destinigo.talktoastro.shared.remote.Resources
import tta.destinigo.talktoastro.shared.theme.background_color

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CoursesPage(
    component: CoursesComponent
) {

    val state by component.state.collectAsState()
    val stateCourses by component.courses.collectAsState()

    var selectedCategory by remember { mutableStateOf("All") }


    if ((state as Resources.Loading).isLoading) {
        LoadingProgressDialog { }
    }

    val windowWidth = calculateWindowSizeClass().widthSizeClass

    val itemsPerRow = when (windowWidth) {
        WindowWidthSizeClass.Expanded -> 3
        WindowWidthSizeClass.Medium -> 2
        else -> 1
    }

    Scaffold(
        topBar = {
            CourseTopBar { component.onBackPress() }
        },
        containerColor = background_color
    ) { paddingValues ->

        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(paddingValues)

        ) {
            if (windowWidth == WindowWidthSizeClass.Compact) {
                Column(
                    modifier = Modifier.fillMaxWidth()
                ) {

                    if (stateCourses?.categoryList.isNullOrEmpty().not()) {
                        CategoryChips(
                            modifier = Modifier.fillMaxWidth(),
                            categories = stateCourses?.categoryList!!,
                            selectedCategory = selectedCategory,
                            onCategorySelected = {
                                selectedCategory = it
                                component.onCategoryChange(it)
                            }
                        )
                    }
                }
            } else {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Spacer(
                        modifier = Modifier.width(18.dp)
                    )
                    Text(
                        text = "Explore Our Courses",
                        style = MaterialTheme.typography.headlineSmall.copy(
                            color = Color.Black,
                            fontWeight = FontWeight.Bold,
                        ),
                        modifier = Modifier.weight(1f)
                    )

                    if (stateCourses?.categoryList.isNullOrEmpty().not()) {
                        CategoryChips(
                            modifier = Modifier.weight(3f),
                            categories = stateCourses?.categoryList!!,
                            selectedCategory = selectedCategory,
                            onCategorySelected = {
                                selectedCategory = it
                                component.onCategoryChange(it)
                            }
                        )
                    }

                    Spacer(
                        modifier = Modifier.width(8.dp)
                    )
                }
            }

            LazyVerticalGrid(
                columns = GridCells.Fixed(itemsPerRow),
                modifier = Modifier.fillMaxSize(),
                contentPadding = PaddingValues(
                    start = 16.dp,
                    end = 16.dp,
                    bottom = 8.dp
                ),
                verticalArrangement = Arrangement.spacedBy(12.dp), // Spacing between rows
                horizontalArrangement = Arrangement.spacedBy(12.dp) //
            ) {

                if (stateCourses?.data.isNullOrEmpty().not()) {

                    itemsIndexed(stateCourses?.data!!) { _, item ->

                        MasterclassCard(
                            masterclass = item,
                            modifier = Modifier,
                            onClickRegister = { courseData ->
                                component.onRegisterButtonClickNow(courseData)
                            }
                        )
                    }

                }
            }
        }
    }
}