package tta.destinigo.talktoastro.feature_course.data

import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import tta.destinigo.talktoastro.feature_course.data.request.CourseBuyRequest
import tta.destinigo.talktoastro.feature_course.data.request.CourseBuyVerifyRequest
import tta.destinigo.talktoastro.feature_course.data.request.CourseDetailsSubmitForm
import tta.destinigo.talktoastro.feature_course.data.request.CourseRazorpayOrderRequest
import tta.destinigo.talktoastro.feature_course.data.request.ValidateCouponRequest
import tta.destinigo.talktoastro.feature_course.data.response.CourseBuyData
import tta.destinigo.talktoastro.feature_course.data.response.CourseBuyResponse
import tta.destinigo.talktoastro.feature_course.data.response.coupon.ValidateCouponData
import tta.destinigo.talktoastro.feature_course.data.response.coupon.ValidateCouponResponse
import tta.destinigo.talktoastro.feature_course.data.response.course.CourseResponse
import tta.destinigo.talktoastro.feature_course.data.response.course_order_id.CourseOrderIdData
import tta.destinigo.talktoastro.feature_course.data.response.course_order_id.CourseOrderIdResponse
import tta.destinigo.talktoastro.feature_course.data.response.courses.CourseData
import tta.destinigo.talktoastro.feature_course.data.response.courses.CoursesData
import tta.destinigo.talktoastro.feature_course.data.response.courses.CoursesResponse
import tta.destinigo.talktoastro.feature_course.data.response.status_verify.CourseBuyStatusResponse
import tta.destinigo.talktoastro.shared.data.CommonResponse
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources
import tta.destinigo.talktoastro.shared.remote.makeApiCall

class CoursesApi : KtorApi() {

    companion object{
        private const val COURSE_PREFIX = "course/"
        const val POST_REQUEST_COURSE_DETAILS = "store-quotes-request-data"
        private const val GET_COURSES = "bootcamps"
        private const val COURSE_BUY = COURSE_PREFIX + "buy"
        private const val COURSE_ORDER_ID = COURSE_PREFIX + "order-id"
        private const val COURSE_BUY_STATUS_VERIFY = COURSE_PREFIX + "purchage-status-verify"
        private const val COURSE_VALIDATE_COUPON = COURSE_PREFIX + "validate-coupon"
    }


    private suspend fun userInterest(request: CourseDetailsSubmitForm): CommonResponse {
        return client.post {
            url(POST_REQUEST_COURSE_DETAILS)
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()
    }

    private suspend fun getCourses(): CoursesResponse {
        return client.get {
            url(GET_COURSES)
            contentType(ContentType.Application.Json)
        }.body()
    }

    suspend fun getCoursesApi(): Resources<List<CoursesData>> {
        return makeApiCall(
            apiCall = { getCourses() },
            transform = { it.data }
        )
    }

    private suspend fun getCourse(slug: String): CourseResponse {
        return client.get {
            url("$GET_COURSES/$slug")
            contentType(ContentType.Application.Json)
        }.body()
    }

    suspend fun getCourseApi(slug: String): Resources<CourseData?> {
        return makeApiCall(
            apiCall = { getCourse(slug) },
            transform = { it.data }
        )
    }


    suspend fun postCourseDetailsRemote(request: CourseDetailsSubmitForm): Resources<Boolean> {
        return makeApiCall(
            apiCall = { userInterest(request) },
            transform = { it.status }
        )
    }

    private suspend fun getCourseTransactionId(request: CourseBuyRequest): CourseBuyResponse {
        return client.post {
            url(COURSE_BUY)
            setBody(request)
        }.body()
    }

    suspend fun getCourseTransactionIdApi(request: CourseBuyRequest): Resources<CourseBuyData?> {
        return makeApiCall(
            apiCall = { getCourseTransactionId(request) },
            transform = { it.data }
        )
    }

    private suspend fun getCourseOrderId(request: CourseRazorpayOrderRequest): CourseOrderIdResponse {
        return client.post {
            url(COURSE_ORDER_ID)
            setBody(request)
        }.body()
    }

    suspend fun getCourseOrderIdApi(request: CourseRazorpayOrderRequest): Resources<CourseOrderIdData?> {
        return makeApiCall(
            apiCall = { getCourseOrderId(request) },
            transform = { it.data }
        )
    }

    private suspend fun getCourseBuyStatus(request: CourseBuyVerifyRequest): CourseBuyStatusResponse {
        return client.post {
            url(COURSE_BUY_STATUS_VERIFY)
            setBody(request)
        }.body()
    }

    suspend fun getCourseBuyStatusApi(request: CourseBuyVerifyRequest): Resources<Boolean> {
        return makeApiCall(
            apiCall = { getCourseBuyStatus(request) },
            transform = { it.status }
        )
    }

    private suspend fun validateCoupon(request: ValidateCouponRequest): ValidateCouponResponse {
        return client.post {
            url(COURSE_VALIDATE_COUPON)
            setBody(request)
        }.body()
    }

    suspend fun validateCouponApi(request: ValidateCouponRequest): Resources<ValidateCouponData?> {
        return makeApiCall(
            apiCall = { validateCoupon(request) },
            transform = { it.data }
        )
    }

}