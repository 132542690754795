package tta.destinigo.talktoastro.feature_course.persentation.course_page.widget.sections

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.FeaturedVideo
import androidx.compose.material.icons.filled.SportsSoccer
import androidx.compose.material.icons.outlined.StarOutline
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import coil3.compose.AsyncImage
import com.mohamedrejeb.richeditor.model.rememberRichTextState
import tta.destinigo.talktoastro.feature_course.data.response.courses.CourseDetails
import tta.destinigo.talktoastro.shared.theme.Scale
import tta.destinigo.talktoastro.shared.theme.primaryDarkerColor

@Composable
fun OverViewSection(
    description: String,
    certificateUrl: String?,
    modifier: Modifier,
    list: List<CourseDetails>
) {

    Column(
        modifier = modifier
    ) {

        Spacer(modifier = Modifier.padding(12.dp))

        //Overview of Page
        Text(
            text = description.toRichHtmlString(),
        )

        Spacer(modifier = Modifier.padding(12.dp))

        list.forEach { courseDetails ->
            CourseDetails(
                modifier = Modifier,
                details = courseDetails
            )
            Spacer(modifier = Modifier.padding(8.dp))
        }

        Spacer(
            modifier = Modifier.height(16.dp)
        )

        Text(
            text = "Receive a Certificate on completion",
            style = MaterialTheme.typography.headlineSmall.copy(
                color = Color.Black,
                fontWeight = FontWeight.Bold
            )
        )

        Spacer(
            modifier = Modifier.height(16.dp)
        )

        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {

            if (certificateUrl != null) {
                AsyncImage(
                    model = certificateUrl,
                    contentDescription = null,
                    modifier = Modifier.height(100.dp)
                )
            } else {
                Icon(
                    imageVector = Icons.Default.SportsSoccer,
                    contentDescription = null
                )
            }

            Spacer(modifier = Modifier.padding(8.dp))
            Column {
                TextWithStartIconOne(
                    modifier = Modifier,
                    text = "Official And Verified",
                    icon = Icons.Outlined.StarOutline,
                    textStyle = TextStyle(
                        fontSize = 15.sp,
                        color = Color.Black
                    )
                )

                TextWithStartIconOne(
                    modifier = Modifier,
                    text = "Easily Shareable",
                    icon = Icons.Outlined.StarOutline,
                    textStyle = TextStyle(
                        fontSize = 15.sp,
                        color = Color.Black
                    )
                )

                TextWithStartIconOne(
                    modifier = Modifier,
                    text = "Enhances Your Credibility",
                    icon = Icons.Outlined.StarOutline,
                    textStyle = TextStyle(
                        fontSize = 15.sp,
                        color = Color.Black
                    )
                )

                TextWithStartIconOne(
                    modifier = Modifier,
                    text = "Placement Support",
                    icon = Icons.Outlined.StarOutline,
                    textStyle = TextStyle(
                        fontSize = 15.sp,
                        color = Color.Black
                    )
                )
            }
        }

        Spacer(modifier = Modifier.padding(32.dp))
    }
}


@Composable
fun CourseDetails(
    modifier: Modifier,
    details: CourseDetails
) {

    Box(
        modifier = modifier.background(
            color = Color.White,
            shape = RoundedCornerShape(Scale.CORNER_SMALL)
        ),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier.padding(16.dp)
        ) {

            Row {
                Text(
                    text = details.title.toString(),
                    style = MaterialTheme.typography.bodyLarge.copy(
                        color = Color.Black,
                        fontWeight = FontWeight.Bold
                    )
                )

                Box(
                    modifier = Modifier.weight(1f)
                ) {
                    Icon(
                        modifier = Modifier.align(Alignment.CenterEnd),
                        imageVector = Icons.AutoMirrored.Filled.FeaturedVideo,
                        contentDescription = null,
                        tint = primaryDarkerColor
                    )
                }
            }

            Text(
                text = details.content.toString(),
                color = Color.Black
            )
        }
    }


}

@Composable
fun String.toRichHtmlString(): AnnotatedString {
    val state = rememberRichTextState()

    LaunchedEffect(this) {
        state.setHtml(this@toRichHtmlString)
    }

    return state.annotatedString
}