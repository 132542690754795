package tta.destinigo.talktoastro.feature_course.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.shared.data.CommonResponse

@Serializable
data class CourseBuyResponse(
    @SerialName("data") val data: CourseBuyData?
) : CommonResponse()

@Serializable
data class CourseBuyData(
    @SerialName("course_id") var courseId: String? = null,
    @SerialName("txn_id") var txnId: Int? = null,
    @SerialName("course_purchase_id") var coursePurchaseId: Double? = null,
    @SerialName("is_course_free") var isCourseFree: Boolean? = null
)

