package tta.destinigo.talktoastro.feature_course.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_course.data.CoursesApi
import tta.destinigo.talktoastro.feature_course.domain.CourseRepo
import tta.destinigo.talktoastro.feature_course.domain.CoursesRepo
import tta.destinigo.talktoastro.feature_course.persentation.course_page.CourseComponent
import tta.destinigo.talktoastro.feature_course.persentation.courses_page.CoursesComponent

val courseDiModule = module {

    factory { CoursesApi()}
    scope<CoursesComponent> {
        scoped { CoursesRepo(get()) } // Course List
    }

    scope<CourseComponent> {
        scoped { CourseRepo(get()) }
    }
}